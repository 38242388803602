<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        المشرفين
                        <input type="text" @change="search()" @keyup="search()" v-model="q" placeholder="ابحث هنا...">
                        &nbsp;
                        <button class="btn btn-primary" v-b-modal.add style="padding: 10px; padding-top: 8px; padding-right: 8px; border-radius: 50%; height: 30px; width: 30px;">
                            <i class="fa fa-plus"></i>
                        </button>
                        <button class="btn btn-success" v-b-modal.send style="margin-right: 5px; padding: 10px; padding-top: 8px; padding-right: 8px; border-radius: 50%; height: 30px; width: 30px;">
                            <i class="fa fa-whatsapp"></i>
                        </button>
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th>
                                    الاسم
                                </th>
                                <th>
                                    اسم المستخدم
                                </th>
                                <th>
                                    النوع
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="admin in admins" :key="admin._id">
                                    <td>
                                        {{ admin.name }}
                                    </td>
                                    <td>
                                        {{ admin.username }}
                                    </td>
                                    <td>
                                        {{ admin.type == 1 ? 'للموقع' : 'للتطبيق' }}
                                    </td>
                                    <td>
                                        <b-dropdown dropleft style="width: 100%;"
                                            id="dropdown-1x"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            text="خيارات"
                                            variant="secondary"
                                            size="sm"
                                        >
                                            <b-dropdown-item @click="current = admin" v-b-modal.edit>
                                                <i class="fa fa-edit"></i>
                                                تعديل المشرف
                                            </b-dropdown-item>
                                            <b-dropdown-item style="font-size: 12px" @click="deleteUser(admin._id)">
                                                <i class="fa fa-trash"></i>
                                                حذف المشرف
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="send" title="ارسال رسائل للمشرفين" hide-footer>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="3"></textarea>
        </div>
        <div class="card card-body border">
              المتغيرات:
              <br><br>
              <ul>
                  <li>
                      {name} => الاسم
                  </li>
                  <li>
                      {username} => اسم المستخدم
                  </li>
                  <li>
                      {password} => كلمة المرور
                  </li>
              </ul>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="sendNow()">
                ارسال الان بالواتس الذكي
            </button>
        </div>
    </b-modal>
    <b-modal id="add" title="اضافة مشرف" hide-footer>
        <div class="form-group">
          <label for="">
            الاسم
          </label>
          <input type="text"
            class="form-control" v-model="create.name">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="createNow()">
                اضافة المشرف
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل المشرف" hide-footer>
        <template v-if="current._id">
            <div class="form-group">
            <label for="">
                الاسم
            </label>
            <input type="text"
                class="form-control" v-model="current.name">
            </div>
            <div class="form-group">
            <label for="">
                الهاتف
            </label>
            <input type="text"
                class="form-control" v-model="current.phone">
            </div>
            <div class="form-group">
            <label for="">
                اسم المستخدم
            </label>
            <input type="text"
                class="form-control" v-model="current.username">
            </div>
            <div class="form-group">
            <label for="">
                كلمة المرور
            </label>
            <input type="text"
                class="form-control" v-model="current.password">
            </div>
            <div class="form-group">
              <label for="">النوع</label>
              <select class="form-control" v-model="current.type">
                <option :value="1">مشرف الموقع</option>
                <option :value="2">مشرف للتطبيق</option>
              </select>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" :value="'*'" v-model="current.pers">
                اعطاء كامل الصلاحيات
              </label>
            </div>
            <br>
            <div class="card card-body border" v-if="!current.pers.includes('*')">
                <template>
                    <span>--- الحافلات ---</span>
                    <div class="form-check" v-for="b in busses" :key="b._id">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" :value="b._id">
                        {{ b.title }}
                      </label>
                    </div>
                    <hr>
                    <span>--- الخيم ---</span>
                    <div class="form-check" v-for="b in camps" :key="b._id">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" :value="b._id">
                        {{ b.title }}
                      </label>
                    </div>
                </template>
                <template v-if="current.type == 2">
                  <hr>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="send-location">
                        ارسال للوكيشن من التطبيق
                      </label>
                    </div>
                </template>
                <template v-if="current.type == 1">
                    <hr>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="users">
                        التحكم في الحجاج
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="arrange">
                        توزيع الحجاج
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="reset-camp">
                        ازالة سكن الحجاج
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="trips">
                        الرحلات
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="hamla_1">
                        التحكم في حافلات "الباقة 2"
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="hamla_2">
                        التحكم في حافلات "الباقة 4"
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="scan">
                        تحضير السكن بالباركود
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="camps_1">
                        التحكم في سكن منى / مكة
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="camps_2">
                        التحكم في سكن عرفات
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="orders">
                        التحكم في الطلبات
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="problems">
                        التكم في الشكاوي
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="posts">
                        التحكم في الاخبار
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="jobs">
                        التحكم في الوظائف
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="rate-all">
                        تقييم كل المشرفين
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="rate-section">
                        تقييم القسم الذي فيه
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="add-tasks">
                        اضافة مهام للمشرفين
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="commitment">
                        التحكم في العهدة
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="cards">
                        التحكم في بطاقات النسك
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="awareness">
                        التوعية
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="tasks2">
                        الوظائف الإضافية
                      </label>
                    </div>
                </template>
                <template v-if="current.type == 1 || current.type == 2">
                  <hr>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="add-ticket">
                        اضافة بلاغات
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="current.pers" value="view-tickets">
                        استعراض البلاغات
                      </label>
                    </div>
                    <div class="g" v-if="current.pers.includes('view-tickets') || current.pers.includes('add-ticket')">
                      <div class="border g">
                        اقسام البلاغات:<br>
                        <div class="form-check" v-for="c in categories" :key="c">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="current.pers" :value="c">
                            {{ c }}
                          </label>
                        </div>
                      </div>
                    </div>
                </template>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    تعديل المشرف
                </button>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {pers: []},
            admins: [],
            user: JSON.parse(localStorage.getItem("user")),
            create: {},
            busses: [],
            camps: [],
            message: "",
            q: "",
            original_admins: [],
            categories: []
        }
    },
    created(){
        if(!checkPer('*')){
            this.$router.push('/pers')
            return;
        }
        this.getBusses()
        var g = this;
        $.post(api + '/admin/busses/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.busses = r.response;
        })
        $.post(api + '/admin/camps/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.camps = r.response;
        })
        $.post(api + '/admin/web/settings', {
            jwt: g.user.jwt
        }).then(function(r){
            g.categories = (r.response.ticket_categories ?? "")?.split(",");
        })
    },
    methods: {
        search(){
            var g = this;
            if(g.q != "" && g.q){
                var admins = [];
                g.original_admins.forEach(element => {
                    if(element?.phone?.includes(g.q) || element?.name?.includes(g.q) || element?.username?.includes(g.q)){
                        admins.push(element)
                    }
                });
                g.admins = admins
            }else{
                g.admins = g.original_admins
            }
        },
        getBusses(){
            var g = this;
            $.post(api + '/admin/admins/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.admins = r.response;
                g.original_admins = r.response;
            })
        },
        createNow(){
            var g = this;
            $.post(api + '/admin/admins/add', {
                jwt: g.user.jwt,
                obj: g.create
            }).then(function(r){
                g.getBusses();
                alert("تم الاضافة بنجاح", 100)
            })
        },
        deleteUser(id){
            if(confirm("متأكد من حذف المشرف؟")){
                var g = this;
                $.post(api + '/admin/admins/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(a){
                    g.getBusses();
                    alert("تم الحذف بنجاح", 100);
                })
            }
        },
        save(){
            var g = this;
            if(g.current.persx == '*'){
                g.current.pers = ["*"]
            }
            $.post(api + '/admin/admins/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                obj: g.current
            }).then(function(a){
                g.getBusses();
                alert("تم التعديل بنجاح", 100);
            })
        },
        sendNow(){
            var g = this;
            $.post(api + '/admin/admins/send', {
                jwt: g.user.jwt,
                message: g.message
            }).then(function(a){
                alert("تم الارسال بنجاح", 100);
            })
        }
    }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>